import axios from "axios";

// export const getShopType = async (authtoken) => {
//     return await axios.get(`${process.env.REACT_APP_API}/get-shop-type`, {
//       headers: {
//         session: authtoken,
//       },

//     });
//   };

//   export const getShopOnlineType = async (authtoken) => {
//     return await axios.get(`${process.env.REACT_APP_API}/get-online-shop`, {
//       headers: {
//         session: authtoken,
//       },

//     });
//   };

// export const getEventShop = async (authtoken) => {
//   return await axios.get(`${process.env.REACT_APP_API}/get-event-shop`, {
//     headers: {
//       session: authtoken,
//     },

//   });
// };

//   export const getReceiptProduct = async (authtoken,type) => {
//     return await axios.get(`${process.env.REACT_APP_API}/get-receipt-product-by-type`, {
//       headers: {
//         session: authtoken,
//       },
//       params: {
//         type: type
//       }
//     });
//   }


export const getCreditMeetAndGreet = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/get-credit`, {
    headers: {
      session: authtoken,
    },

  });
};

export const addMeetAndGreet = async (authtoken, receipt) => {
  return await axios.post(`${process.env.REACT_APP_API}/add-receipt-campaign`, receipt, {
    headers: {
      session: authtoken,
    },
  });
};