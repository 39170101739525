import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import cloudinary from "cloudinary/lib/cloudinary";

import "./App.css";
import Register from "./pages/register";
import Home from "./pages/home";
import Upload_receipt from "./pages/Upload_receipt";
import Upload_meetandgreet from "./pages/Upload_meetandgreet";
import redeem_rewards from "./pages/redeem_rewards";
import register_pets from "./pages/register_pets";
import add_address from "./pages/add_address";
import delivery from "./pages/delivery";
import EditProfile from "./pages/edit_profile";
import profile from "./pages/profile";
import Edit_address from "./pages/edit_address";
import EditPets from "./pages/EditPets";
import SuccessRegisterPage from "./pages/SuccessRegisterPage";
import AlertSuscess from "./pages/alertSuscess";
import Add_addressRedeem from "./pages/add_addressRedeem";
import { userID_ } from "./functions/userAddress";
import UserRoute from "./components/routes/UserRoute";
import Delivery from "./pages/delivery";
import LoginLineComplete from "./pages/LoginLineComplete";
import { getLogin } from "./functions/member";
import S__ from "./pages/__";
import PetShop from "./pages/petShop";
import LineLogin from "./pages/LineLogin";
import LineLogin2 from "./pages/LineLogin2";
import Login from "./pages/Login";
import LineLoginProd from "./pages/LineLoginProd";
import LuckyDraw from "./pages/lucky_draw";
import LuckyDrawDetail from "./pages/lucky_draw_detail";
import LuckyDrawExchange from "./pages/lucky_draw_exchange";
import Select_campaign from "./pages/select_campaign";

cloudinary.config({
  cloud_name: process.env.REACT_APP_CLOUD_NAME,
  api_key: process.env.REACT_APP_API_KEY,
  api_secret: process.env.REACT_APP_API_SECRET,
});

const App = ({ history }) => {
  // add liff id
  // const liffId = "2003680708-QMgnB3vd"
  // const liffIdProd = "1657890603-m5nkdgq8"

  // const liffId = "2003848518-pYEnWv62"
  // const liffIdProd = "1660688630-NeqEEQ62"

  // const liffId = "1660688630-NeqEEQ62"
  // const liffIdProd = "2004859316-2pyBv7Y8"

  // const liffId = "2003680708-QMgnB3vd";
  // const liffId2 = "1657890603-m5nkdgq8";
  // const liffIdProd = "2000370361-9n7KYEBG";

  // Prodution  /////////
  const liffId = "1660688630-NeqEEQ62";
  const liffId2 = "2003848518-pYEnWv62";
  const liffIdProd = "2004859316-2pyBv7Y8";
  //------------------//

  // ------------UAT Meet&Greet ------------- //
  // const liffId = "1661110329-vPxWabm6";
  // const liffId2 = "2006383440-LQAjPBb4";
  // const liffIdProd = "2006383449-yQVd7GZN";


  // ---------------------------------------- //


  sessionStorage.setItem("liffId", liffId);
  sessionStorage.setItem("liffId2", liffId2);
  sessionStorage.setItem("liffIdProd", liffIdProd);
  // useEffect(() => {
  //   const liffId = "2003680708-QMgnB3vd"
  //   const liffIdProd = "1657890603-m5nkdgq8"

  //   sessionStorage.setItem("liffId", liffId);
  //   sessionStorage.setItem("liffIdProd", liffIdProd);
  // }, []);

  // for test start here
  // useEffect(() => {
  //   const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwibWVtYmVyX2lkIjoxMDA0LCJjcmVhdGVkX2F0IjoiMjAyNC0wMy0yNyAyMTozNDo1OCJ9.dvT7DgGPZOo-_AiIiEuxVYkCtTvGy9gLkQUUyjlsndQ";
  //   // mock data
  //   const data = {
  //     user: {
  //       firstname: "resProfile.firstname",
  //       surename: "resProfile",
  //       gender: "res",
  //       tel: "resProfile.tel",
  //       birthdate: "resProfile.birthdate",
  //       email: "resProfile.email",
  //       img: "resProfile.img",
  //       token: token,
  //     },
  //     tokens: token
  //   };

  //   window.localStorage.setItem("auth", JSON.stringify(data));
  // }, []);
  // for test end here

  // UAT config
  // const token = localStorage.getItem("accessToken");
  // const liff = window.liff;

  // useEffect(() => {
  //   handleLocalTest()
  //   return

  //   const unsubscribe = liff
  //     .init({ liffId: process.env.REACT_APP_LINE_LIFF })
  //     .then(async () => {
  //       if (!liff.isLoggedIn()) {
  //         liff.login();
  //       }
  //       else {
  //         let lineAccessToken = liff.getAccessToken();
  //         localStorage.setItem("lineAccessToken", lineAccessToken);
  //         if (token) {
  //           // load profile
  //           loadProfile(token)
  //         }
  //         else {
  //           // new login
  //           liff.getProfile().then(profile => {
  //             handlerLogin(profile.userId, lineAccessToken)
  //           }).catch(err => console.error(err));

  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   return () => unsubscribe();

  // }, [dispatch, token]);

  // const clearData = () => {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("addressStatus");
  //   localStorage.removeItem("petStatus");
  //   localStorage.removeItem("lineUserPic");
  //   localStorage.removeItem("lineUserId");

  //   dispatch({
  //     type: "LOGOUT",
  //     payload: {},
  //   });
  // };

  // const handleLocalTest = () => {
  //   let _token =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6OCwibWVtYmVyX2lkIjoxMDA0LCJjcmVhdGVkX2F0IjoiMjAyMy0wNS0yMiAxNzo0Mzo1MSJ9.TnaHIqbdRtdeX4avOOUW4CM6cc-ywMBGryQ7DtoeERA";

  //   localStorage.setItem(
  //     "lineAccessToken",
  //     "eyJhbGciOiJIUzI1NiJ9.1tcUjWLJCFK2lNb3soATgZMNJrq-CNbDmba_CqZOq_DF2GJHubyTIWnEg0WICCJhveuAxFd5uAdf0haLBR4AB1CeyLlXxiYbab9fFlg6dEkvnXlxhvTyHQ0PWo5xQ2k8yddfaK2DbRcxP_4zItGMaxSh2Trgp13LUZXyHDe6ruY.JKQFgxQz4vSv4ofBd2A9kMRH93SfIWXUb0ewW7LfUn8"
  //   );
  //   localStorage.setItem("accessToken", _token);
  //   dispatch({
  //     type: "LOGGED_IN_USER",
  //     payload: {
  //       firstname: "resProfile.firstname",
  //       surename: "resProfile.surename",
  //       gender: "resProfile.gender",
  //       tel: "resProfile.tel",
  //       birthdate: "resProfile.birthdate",
  //       email: "resProfile.email",
  //       img: "resProfile.img",
  //       token: _token,
  //     },
  //   });
  // };

  // const loadProfile = (sessionToken) => {
  //   userID_(sessionToken)
  //     .then((res) => {
  //       let resProfile = res.data.data;
  //       dispatch({
  //         type: "LOGGED_IN_USER",
  //         payload: {
  //           firstname: resProfile.firstname,
  //           surename: resProfile.surename,
  //           gender: resProfile.gender,
  //           tel: resProfile.tel,
  //           birthdate: resProfile.birthdate,
  //           email: resProfile.email,
  //           img: resProfile.img,
  //           token: sessionToken,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       clearData();
  //       console.log(err);
  //     });
  // };

  // const handlerLogin = (line_id, access_token) => {
  //   getLogin({
  //     line_id: line_id,
  //     access_token: access_token,
  //   })
  //     .then((res) => {
  //       let logins = res.data;

  //       localStorage.setItem("accessToken", logins.data.session);
  //       localStorage.setItem("addressStatus", logins.address_status);
  //       localStorage.setItem("petStatus", logins.pet_status);

  //       loadProfile(logins.data.session);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <Router>
      <div className="App">
        <Switch>

          <Route exact path="/" component={LineLogin}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/select_campaign" component={Select_campaign}></Route>
          {/* <Route exact path="/" component={Register}></Route> */}
          <Route
            exact
            path="/loginLineComplete"
            component={LoginLineComplete}
          ></Route>
          <Route exact path="/register" component={Register}></Route>
          <Route
            exact
            path="/successRegister"
            component={SuccessRegisterPage}
          ></Route>
          <UserRoute exact path="/home" component={Home}></UserRoute>
          <UserRoute
            exact
            path="/Upload_receipt"
            component={Upload_receipt}
          ></UserRoute>
          <UserRoute
            exact
            path="/Upload_meetandgreet"
            component={Upload_meetandgreet}
          ></UserRoute>
          <UserRoute
            exact
            path="/redeem_rewards"
            component={redeem_rewards}
          ></UserRoute>
          <UserRoute
            exact
            path="/register_pets"
            component={register_pets}
          ></UserRoute>
          <UserRoute
            exact
            path="/add_address"
            component={add_address}
          ></UserRoute>
          <UserRoute exact path="/delivery" component={delivery}></UserRoute>
          <UserRoute
            exact
            path="/edit_profile"
            component={EditProfile}
          ></UserRoute>
          <UserRoute exact path="/profile" component={profile}></UserRoute>
          <UserRoute
            exact
            path="/edit_address/:id"
            component={Edit_address}
          ></UserRoute>
          <UserRoute
            exact
            path="/EditPets/:id"
            component={EditPets}
          ></UserRoute>
          <UserRoute
            exact
            path="/alertSuscess/:page"
            component={AlertSuscess}
          ></UserRoute>
          <UserRoute
            exact
            path="/delivery/:id/:point"
            component={Delivery}
          ></UserRoute>
          <UserRoute
            exact
            path="/add_addressRedeem"
            component={Add_addressRedeem}
          ></UserRoute>
          <UserRoute exact path="/__" component={S__}></UserRoute>
          <UserRoute exact path="/petShop" component={PetShop}></UserRoute>
          <UserRoute exact path="/lucky-draw" component={LuckyDraw}></UserRoute>
          <UserRoute
            exact
            path="/lucky-draw-detail"
            component={LuckyDrawDetail}
          ></UserRoute>
          <UserRoute
            exact
            path="/lucky-draw-exchange"
            component={LuckyDrawExchange}
          ></UserRoute>
          <Route exact path="/lineLoginProd" component={LineLoginProd}></Route>
          <Route exact path="/lineLogin2" component={LineLogin2}></Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
