import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Menu from "../components/menu";
import Banner_KV from "../components/banner_KV";
import LuckyTermsAndConditions from "../components/lucky_term";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Carousel from "react-bootstrap/Carousel";

const { confirm } = Modal;

const Select_campaign = ({ history }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const profireStatus = localStorage.getItem("member_status");
  const accepted_campaign = localStorage.getItem("accepted_campaign");
  // for test
  // const accepted_campaign = false;
  // end test
  const [token, setToken] = useState("");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MywibWVtYmVyX2lkIjoxLCJjcmVhdGVkX2F0IjoiMjAyNC0wMy0yOCAxNzowOToyMCJ9.Y9O8oN0khCWBqCYQ0nZzkXO6MOwrW4V3kD24SG2gwUw";
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // window.location.href = "home";
    // return;

    // if (addressStatus === "0" || petStatus === "0" || profireStatus === "0") {
    //   // let type = petStatus === "0" ? "pet" : "address";
    //   showPromiseConfirm();
    // }

    const { user } = JSON.parse(window.localStorage.getItem("auth"));
    setToken(user.token);
  }, []);

  // const showPromiseConfirm = () => {
  //   let msgTitle = " ";
  //   let msgDesc = " ";
  //   let path = " ";

  //   // console.log("profireStatus",profireStatus)
  //   // console.log("addressStatus",addressStatus)
  //   // console.log("petStatus",petStatus)

  //   // if (profireStatus === "0") {
  //   //   msgTitle = "กรุณาเพิ่มข้อมูลส่วนตัวให้ครบถ้วน";
  //   //   msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
  //   //   path = "/edit_profile";
  //   // } else if (addressStatus === "0") {
  //   //   msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์ตลอดทั้งปี";
  //   //   msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
  //   //   path = "/add_address";
  //   // } else if (petStatus === "0") {
  //   //   msgTitle = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
  //   //   msgDesc = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
  //   //   path = "/register_pets";
  //   // }

  //   confirm({
  //     title: msgTitle,
  //     icon: <InfoCircleOutlined />,
  //     content: msgDesc,
  //     onOk() {
  //       return new Promise((resolve, reject) => {
  //         setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
  //         history.push(path);
  //       }).catch(() => console.log("Oops errors!"));
  //     },
  //     onCancel() { },
  //   });
  // };
  const goHome = () => {
    window.location.href = "home";
    localStorage.setItem("campaignType", "home")
  }

  const goMeet = () => {
    window.location.href = "Upload_meetandgreet";
    localStorage.setItem("campaignType", "meet")
  }

  const goGold = () => {
    window.location.href = "Upload_meetandgreet";
    localStorage.setItem("campaignType", "gold")
  }

  // const checkPermission = () => {
  //   console.log("addressStatus", addressStatus);
  //   // if(accepted_campaign === false){
  //   //   handleModalOpen();
  //   // }else if(accepted_campaign === true){
  //   //   history.push("/lucky-draw-detail");
  //   // }
  //   if (
  //     addressStatus === "1" &&
  //     petStatus === "1" &&
  //     profireStatus === "1" &&
  //     accepted_campaign === "false"
  //   ) {
  //     handleModalOpen();
  //   } else if (
  //     addressStatus === "1" &&
  //     petStatus === "1" &&
  //     profireStatus === "1" &&
  //     accepted_campaign === "true"
  //   ) {
  //     history.push("/lucky-draw-exchange");
  //   } else {
  //     showPromiseConfirm();
  //   }
  // };

  return (
    <div>
      <div className="backgroundHome">
        <br></br>

        <center>
          <div className="d-flex align-items-center home_cam" style={{ marginLeft: '12%' }}>
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="dog_icon"
            ></div>
            <b className="Header" style={{ marginLeft: '20px', marginRight: '20px' }}>MOOCHIE</b>

            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="cat_icon"
            ></div>
          </div>
        </center>

        <br></br>

        <Form>
          {/* <Banner_KV /> */}
          {/* <Carousel
            className="box_ฺBanner"
            activeIndex={0}
            onSelect={() => {
              console.log("onSelect");
            }}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 borderradius20px"
                src="assets/luckyDraw/MooChie_Lucky-draw-ครั้งที่-3-23-9-2024.png"
                alt="p1.ll"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel> */}


          {/* <br></br>
          <div className="luckyDraw_text">
            <img src="" />
          </div>
          <br></br> */}
          {/* <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="Luckydraw-Sub-Header"
          ></div> */}
          <div style={{ height: '130px' }}>

          </div>

          <div className="d-flex align-items-center home_cam">
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                marginLeft: '2%'

              }}
              className="clover_icon"
            ></div>
            <b
              style={{
                fontSize: "2.5em",
                color: '#fff',
                marginLeft: '10px',
                marginRight: '10px'
              }}

            >
              เลือกแคมเปญ
            </b>

            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="clover_icon"
            ></div>
          </div>

          <br></br>
          <br></br>


          <Row>
            <Col span={12}>  
            
              <img
                className="d-block w-100 borderradius20px"
                src="assets/campagin/Web-CRM_Moochie-170x200.png"
                onClick={() => goHome()}
              />
            </Col>
            <Col span={12}>
              <div>
              <img
                className="d-block w-100 borderradius20px"
                src="assets/campagin/Web-CRM_Moochie-170x95_1.png"
                onClick={() => goMeet()}
              />
              </div>
              <div
               style={{ marginBottom: '10px' }}
              >
              </div>
              <img
                className="d-block w-100 borderradius20px"
                src="assets/campagin/WebCRM_Moochie-170x95_2.png"
                onClick={() => goGold()}
              />
            </Col>
          </Row>
          {/* <div className="luckyDraw_banner">
            <img src="" />
          </div> */}
        </Form>
        <div style={{ height: "500px" }}></div>
      </div>

      {/* <Menu /> */}
    </div>
  );
};

export default Select_campaign;
