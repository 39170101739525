import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "../functions/member";
import { userID_ } from "../functions/userAddress";
import Button from "react-bootstrap/Button";
import { LoadingOutlined } from "@ant-design/icons";

const LoginLineComplete = ({ history }) => {
  const liff = window.liff;
  const dispatch = useDispatch();
  const [isRegister, setIsRegister] = useState(true);

  useEffect(() => {
    const unsubscribe = liff
      // ตัวจริง //
      .init({ liffId: '1660688630-NeqEEQ62' })
      // ตัวจริง //

      //ตัว UAT //
      // .init({ liffId: '1661110329-vPxWabm6' })
      //ตัว UAT //
      .then(async () => {
        const lineAccessToken = liff.getAccessToken();
        localStorage.setItem("lineAccessToken", lineAccessToken);

        liff
          .getProfile()
          .then((profile) => {
            getLogin({
              line_id: profile.userId,
              access_token: lineAccessToken,
            })
              .then((res) => {
                setIsRegister(false);
                let logins = res.data;

                localStorage.setItem("accessToken", logins.data.session);
                localStorage.setItem("addressStatus", logins.address_status);
                localStorage.setItem("petStatus", logins.pet_status);
                localStorage.setItem("member_status", logins.member_status);
                localStorage.setItem("accepted_campaign", logins.accepted_campaign);

                loadProfile(logins.data.session);
              })
              .catch((err) => {
                // setIsRegister(true);
                console.log(err);
              });
          })
          .catch((err) => {
            // setIsRegister(true);
            console.error(err);
          });
      })
      .catch((err) => {
        // setIsRegister(true);
        console.log(err);
      });

    return () => unsubscribe();
  }, [dispatch, isRegister]);

  const loadProfile = (sessionToken) => {
    userID_(sessionToken)
      .then((res) => {
        let resProfile = res.data.data;
        dispatch({
          type: "LOGGED_IN_USER",
          payload: {
            firstname: resProfile.firstname,
            surename: resProfile.surename,
            gender: resProfile.gender,
            tel: resProfile.tel,
            birthdate: resProfile.birthdate,
            email: resProfile.email,
            img: resProfile.img,
            token: sessionToken,
          },
        });

        window.location.href = "/home";
      })
      .catch((err) => {
        clearData();
        console.log(err);
      });
  };

  const clearData = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("addressStatus");
    localStorage.removeItem("petStatus");
    localStorage.removeItem("member_status");
    localStorage.removeItem("lineUserPic");
    localStorage.removeItem("lineUserId");
    localStorage.removeItem("lineAccessToken");
    dispatch({
      type: "LOGOUT",
      payload: {},
    });
  };

  const handlePage = () => {
    window.location.href = "/register";
  };

  return (
    <center>
      <div
        className="backgroundMain"
        style={{ paddingTop: "50%", height: "900px" }}
      >
        <div className="success"></div>
        <br></br>
        <b className="Header_regis_pets " style={{ fontSize: "30px" }}>
          {" "}
          ยืนยันตัวตนกับทาง Line สำเร็จ
        </b>
        <br></br>
        <br></br>

        {isRegister ? (
          <Button
            style={{
              backgroundColor: "#FF0099",
              borderColor: "#FF0099",
              width: "190px",
            }}
            variant="primary"
            type="submit"
            onClick={handlePage}
          >
            ไปยังหน้าสมัครสมาชิก{" "}
          </Button>
        ) : (
          <LoadingOutlined className="text-danger" />
        )}
      </div>
    </center>
  );
};

export default LoginLineComplete;
