import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { useDispatch } from "react-redux";

const UserRoute = ({ children, ...rest }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  //---- UAT ใช้สำหรับจำลองเอา auth ในการ dev บนเครื่อง-------//

  // const data = {
  //   user: {
  //     firstname: "ฟหกฟหก",
  //     gender: "female",
  //     tel: "0844098902",
  //     birthdate: "2023-11-30",
  //     email: "asd@sad.com",
  //     img: "https://profile.line-scdn.net/0hoqbFQl6eMF5PPyNyX9BOIT9vMzRsTmlMal0qb38_bmclCHABZwx2bXI_aWp2X3IAZg53OntqPTpDLEc4UWnMakgPbW9zD38Ka1p4uQ",
  //     token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjM5ODQsIm1lbWJlcl9pZCI6ODU0MywiY3JlYXRlZF9hdCI6IjIwMjQtMTEtMjggMTM6NDg6NTUifQ.d0SY_LbJJ5a5iqqtfxVwvBysKQseMITh3b1SutkQYGc"
  //   },
  //   tokens: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjM5ODQsIm1lbWJlcl9pZCI6ODU0MywiY3JlYXRlZF9hdCI6IjIwMjQtMTEtMjggMTM6NDg6NTUifQ.d0SY_LbJJ5a5iqqtfxVwvBysKQseMITh3b1SutkQYGc"
  // };

  // localStorage.setItem("auth", JSON.stringify(data));
  // localStorage.setItem("lineAccessToken", "eyJhbGciOiJIUzI1NiJ9.osWPjzHz92cb7eMwLnmWeCiJG5Z1zktioQyA9vdxblDhqBlF4d35xGPmhc6pjOy6tGcjSqU59yqB2rnf23t5WYg56Ok-CKX9k8mRzFQP4wYTIZ8i0WUslfDWnWXY-ipWFgu89S9AWe7UNZIixaaVwx8RFq7kSs1xJlzCZIXSFME.c_hvppGgdpohVYSy5TviWYYsViDS-sN4oUhAcRmwa0E")

  // สำหรับไว้เช็คที่อยู่
  // localStorage.setItem("addressStatus", '0')

  //---- UAT ใช้สำหรับหลอกเอา auth ในการ dev บนเครื่อง-------//

  const auth = JSON.parse(window.localStorage.getItem("auth"));



  return auth && auth.user ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default UserRoute;
